<template>
    <modal  class="line-graph-width" :show.sync="$store.state.modals.lostPatientsLineGraphModal">
      <template slot="header">
        <span></span>
      </template>
      <template class="">
        <div class="row">
          <div class="col-3">
            <h5 class="mt-2 title-class">Lost Patients</h5>
          </div>
          <div class="col-9 dropdown-padding-right">
            <select class="form-control py-0 w-25 float-right time-gap-dropdown" v-model="timeGap"
                    @change="getLostPatients">
              <option value="months -6" selected>6 Months</option>
              <option value="years -1">1 Year</option>
              <option value="years -2">2 Year</option>
              <option value="years -3">3 year</option>
              <option value="years -5">5 Year</option>
            </select>
          </div>
        </div>
      </template>
      <template>
        <div class="mt--4 ml--1" v-loading="loader">
          <line-chart
            class="mt-5"
            :height="350"
            ref="lostPatientLineGraph"
            :chart-data="lineGraph.data"
            :extra-options="lineGraph.options"
          />
        </div>
      </template>
    </modal>
</template>

<script>
import LineChart from '@/components/Charts/LineChart';

export default {
  name: "LostPatientLineGraphModal",
  props: ['lostPatients', 'monthLabels', 'loader'],
  components: {
    LineChart
  },
  data() {
    return {
      timeGap: 'months -6',
      lineGraph: {
        data: {
          labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
          datasets: [{
            label: 'Patients',
            data: [0, 0, 0, 0, 0, 0],
            borderColor: "#2F597A",
            pointBackgroundColor: "#832C44",
            borderWidth: 3,
            pointBorderColor: "#FFFFFF",
            pointBorderWidth: 3,
            pointStrokeColor: "#fff",
            pointHitRadius: 1,
            pointRadius: 5,
          }]
        },
        options: {
          responsive: true,
          scales: {
            yAxes: [{
              ticks: {
                suggestedMin: 0,
                maxTicksLimit: 6,
                beginAtZero: true,
                stepSize: 10
              },
              display: true,
            }]
          }
        }
      }
    }
  },
  watch: {
    lostPatients: function (newVal) {
      Object.assign(this.lineGraph.data.datasets[0].data, newVal)
      this.assignStepSize(newVal)
      this.renderLineGraph()
    },

    monthLabels: function (newVal) {
      Object.assign(this.lineGraph.data.labels, newVal)
      this.renderLineGraph()
    }
  },
  methods: {
    /**
     * Get Lost Patients Graph Data
     */
    getLostPatients() {
      this.$emit('get-lost-patients', this.timeGap)
    },

    /**
     * Assign StepSize
     * @param patientsData
     */
    assignStepSize(patientsData) {
      let size = Math.max(patientsData) / 4
      size = Math.ceil(size / 10) * 10
      this.lineGraph.options.scales.yAxes[0].ticks.stepSize = size
    },

    /**
     * Render the Graph
     */
    renderLineGraph() {
      this.$nextTick(() => {
        this.$refs.lostPatientLineGraph.renderChart(this.lineGraph.data, this.lineGraph.options)
      })
    }
  }
}
</script>

<style scoped>

.line-graph-width >>> .modal-content, .line-graph-width >>> .modal-header, .line-graph-width >>> .modal-body {
  width: 900px;
}

.line-graph-width >>> .modal-dialog {
  justify-content: center;
}

.line-graph-width >>> .modal-body {
  padding-top: 0;
}

.line-graph-width >>> .close {
  padding-bottom: 0;
}

.line-graph-width >>> .close > span{
  font-size: 30px;
  color: black;
}

.title-class{
  text-align: left;
  font: normal normal bold 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #172B4D;
  text-transform: uppercase;
  opacity: 1;
}

.time-gap-dropdown {
  height: 32px;
  font-size: 15px;
  font-weight: 600;
  background: 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 6px #32325D1C;
  border: 1px solid #ffffff;
  border-radius: 6px;
  opacity: 1;
}

.dropdown-padding-right {
  padding-right: 60px;
}
</style>
