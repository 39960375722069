<template>
  <div>
    <div class="modals">

    </div>
    <div class="row">
      <div class="col-12 d-flex">
        <div class="table-responsive">
          <el-table :data="response.tblMergeablePatientsData"
                    row-key="id"
                    class="table-responsive table-flush"
                    header-row-class-name="thead-light"
          >
            <el-table-column label="PATIENT NAME" min-width="110px" max-width="200px">
              <template slot-scope="scope">
                <el-tooltip
                  class="item" effect="dark"
                  :content="hoverPatientInfo(scope.row)"
                  placement="bottom-start"
                  :visible-arrow="false"
                >
                  {{ scope.row.name }}
                </el-tooltip>
              </template>
            </el-table-column>

            <el-table-column label="ACTIONS" min-width="200px">
              <template slot-scope="scope">
                <base-button type="secondary" class="p-0 mr-3 font-weight-normal" @click="onMergePatient(scope.row)">
                  Merge
                </base-button>

              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-3">
        <seminar-surgery-footer :pagination="pagination" @changePage="changePage" @changedPageLength="changedPageLength"/>
      </div>
    </div>
  </div>
</template>

<script>
import SeminarSurgeryFooter from "./SeminarSurgeryFooter";
import {Option, Select, Table, TableColumn} from "element-ui";
export default {
  name: "ChecklistPatients",
  props :  ['tab'],
  components: {
    SeminarSurgeryFooter,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data(){
    return {
      loading : false,
      api_called : false,
      page_change_call : false,
      modals : {
      },
      request : {
        api: {
          patientId:'',
          firstName:'',
          lastName:'',
          currentStage:'Pre-Op',
          completedBy:'',
        },
      },
      response : {
        tblMergeablePatientsData : [],
        stages: [],
        completedByOptions: [],
      },
      pagination : {
        currentPage : 1,
        perPage : 20,
        page:1,
        lastPage:1,
      },
    }
  },
  mounted() {
    if (this.api_called == false){
      this.api_called = true;
      this.page_change_call = false;
      this.getMergeablePatients();
    }
  },
  computed: {

  },
  methods : {

    hoverPatientInfo(row){
      let returnable = ''
      returnable = (row.hasOwnProperty('name') === true) ? returnable+row.name : returnable+''
      returnable = (row.hasOwnProperty('email') === true && row.email !== '' && row.email != null) ? returnable+', '+row.email : returnable+''
      returnable = (row.hasOwnProperty('phone') === true && row.phone !== '' && row.phone != null) ? returnable+', '+row.phone : returnable+''
      returnable = (row.hasOwnProperty('dob') === true && row.dob !== '' && row.dob != null) ? returnable+', '+row.dob : returnable+''
      returnable = (row.hasOwnProperty('mrn_number') === true && row.mrn_number !== '' && row.mrn_number != null) ? returnable + ', ' + row.mrn_number : returnable + ' '
      // returnable = (row.hasOwnProperty('mrn') === true && row.mrn !== '' && row.mrn != null) ? returnable + ', ' + row.mrn : returnable + ' '
      return returnable
    },

    changePage(page){
      this.pagination.page = page;
      if (this.api_called == false && this.page_change_call == true){
        this.api_called = true;
        this.getMergeablePatients();
      }
      this.page_change_call = true;
    },

    changedPageLength(pageLength) {
      this.pagination.perPage = pageLength;
      if (this.api_called == false){
        this.api_called = true;
        this.page_change_call = false;
        this.getMergeablePatients();
      }
    },

    getMergeablePatients(){
      let vm = this;
      vm.loading = true;
      axios.get(this.$store.getters.getBaseUrl + '/api/seminar2surgery/mergeable-patients?page=' + vm.pagination.page+ '&perPage=' + vm.pagination.perPage
      ).then((response) => {
        vm.response.tblMergeablePatientsData = [];
        vm.response.tblMergeablePatientsData = [...response.data.data.data];
        vm.pagination.currentPage = response.data.data.current_page;
        vm.pagination.lastPage = response.data.data.last_page;
        vm.pagination.perPage = response.data.data.per_page;
        vm.pagination.page = response.data.data.current_page;
        vm.pagination.total = response.data.data.total;
        vm.loading = false;
        vm.api_called = false;
      }).catch(error => {
        console.log(error.response);
      });
    },

    onChangeCompletedBy(row){
      let vm = this;
      axios.post(`${this.$store.getters.getBaseUrl}/api/seminar2surgery/checked-by/update`,
        {
          checklistId:row.checklist_id,
          completedBy:row.checked_by,
        }
      )
        .then((response)=>{
          let message = response.data.message;
          vm.$notify.success({
            title : 'Seminar 2 Surgery',
            message: message
          });
          vm.getMergeablePatients();
        })
        .catch((error)=>{
          let message = 'Something went wrong please try again in few minutes.';
          vm.$notify.error({
            title : 'Seminar 2 Surgery',
            message: message
          })
        });
    },

    onMergePatient(){

    },

  },
  watch : {

  }
}
</script>

<style scoped>

</style>
<style>


</style>
