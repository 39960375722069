<template>
  <div>
    <div class="modals">
      <!-- Notification Modal -->
      <modal :show.sync="modals.reminderModal" class="stalledPatientReminderModal">
        <!-- Notification Modal Header -->
        <template slot="header">
          <h5 class="modal-title" id="SendReminderModal">Send a Reminder</h5>
        </template>
        <!-- Notification Modal Header Finished -->

        <!-- Notification Modal Body -->
        <template slot="default">
          <p class="message-modal-text mb-4 stalled-patients-reminder-message">
            This is a push notification. Do not send anything that could be considered Protected Health Information.
            Patients cannot respond to these messages.
          </p>
          <base-input label="Message">
                <textarea
                  v-model="request.notification"
                  class="form-control"
                  id="notification-message"
                  rows="4">
                </textarea>
          </base-input>
        </template>
        <!-- Notification Modal Body Finished -->

        <!-- Notification Modal Footer -->
        <template slot="footer">
          <div class="mt--5">
            <base-button class="btn-purple" type="secondary" @click="modals.reminderModal=false">Cancel</base-button>
            <base-button class="btn-primary-action" type="success" @click="sendReminderNotification">Send Message</base-button>
          </div>
        </template>
        <!-- Notification Modal Footer Finished -->
      </modal>
      <modal :show.sync="modals.snoozeModal" class="stalledPatientSnoozeModal">
        <!-- Notification Modal Header -->
        <template slot="header">
          <h4 class="modal-title font-weight-bold mb-0" id="snoozeModal">Snooze</h4>
        </template>
        <template slot="default" class="mt-0">
          <div class="row">
            <div class="col mt-0 stalled-patients-snooze-message">
              <p>Do you want to snooze this alert? Patient will not show up in Stalled list for selected number of
                days.</p>
            </div>
          </div>
          <div class="row ">
            <div class="col-md-6 d-flex justify-content-center px-0">
              <div class="radioButtonCard my-1 p-1 d-flex align-items-center">
                <base-radio name="r1" v-model="request.selectedSnoozePeriod" value="snoozeOptions.r1.value"
                            class="m-0 base-radio-cls d-flex align-items-center">
                  <span class="base-radio-text">1 Day</span>
                </base-radio>
              </div>
            </div>
            <div class="col-md-6 d-flex justify-content-center px-0">
              <div class="radioButtonCard my-1 p-1 d-flex align-items-center">
                <base-radio name="r2" v-model="request.selectedSnoozePeriod" value="snoozeOptions.r2.value"
                            class="m-0 base-radio-cls d-flex align-items-center">
                  <span class="base-radio-text">7 Days</span>
                </base-radio>
              </div>
            </div>
            <div class="col-md-6 d-flex justify-content-center px-0">
              <div class="radioButtonCard my-1 p-1 d-flex align-items-center">
                <base-radio name="r3" v-model="request.selectedSnoozePeriod" value="snoozeOptions.r3.value"
                            class="m-0 base-radio-cls d-flex align-items-center">
                  <span class="base-radio-text">14 Day</span>
                </base-radio>
              </div>
            </div>
            <div class="col-md-6 d-flex justify-content-center px-0">
              <div class="radioButtonCard my-1 p-1 d-flex align-items-center">
                <base-radio name="r4" v-model="request.selectedSnoozePeriod" value="snoozeOptions.r4.value"
                            class="m-0 base-radio-cls d-flex align-items-center">
                  <span class="base-radio-text">30 Days</span>
                </base-radio>
              </div>
            </div>
          </div>
        </template>
        <template slot="footer">
          <div class="">
            <base-button class="btn-purple" type="secondary" @click="modals.snoozeModal=false">Cancel</base-button>
            <base-button class="btn-primary-action pl-5 pr-5 br-4" type="success" @click="snoozePatient">
              Snooze
            </base-button>
          </div>
        </template>
      </modal>
      <modal :show.sync="modals.deleteModal">
        <template slot="header">
          <h5 class="modal-title">Are you sure?</h5>
        </template>
        <div class="content mt--4 ml--1">
          You want to dismiss this patient from the stalled patient report?
        </div>
        <template slot="footer">
          <base-button class="btn-purple" type="secondary" @click="modals.deleteModal=false">Cancel</base-button>
          <base-button type="danger" @click="dismissStalledPatient">Remove</base-button>
        </template>
      </modal>
      <!-- Notification Modal Finished -->
    </div>
    <div class="row">
      <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap mb-3">
        <div class="d-flex w-100">
          <base-input class="d-inline-block" v-model="request.api.firstName"
                      label="First Name"
                      placeholder="Search..." @change="filterChanged" type="search" @search="filterChanged">
          </base-input>
          <base-input class="d-inline-block ml-2" v-model="request.api.lastName"
                      label="Last Name"
                      placeholder="Search..." @change="filterChanged" type="search" @search="filterChanged">
          </base-input>

          <base-input label="Checklist Item" class="d-inline-block ml-2">
            <p @click="clearChecklistItem" class="d-inline-block float-right mt--4 mr-2 mb-0 clear-txt">Clear</p>
            <el-select
              class="select-primary pagination-select"
              v-model=request.api.currentChecklist
              placeholder="Checklist Item"
              @change="filterChanged"
            >
              <el-option
                class="select-primary"
                v-for="(checklistItem , index) in response.checklistItems"
                :key="index"
                :label="checklistItem"
                :value="checklistItem"
              >
              </el-option>
            </el-select>
          </base-input>

          <base-input label="Current Stage" class="d-inline-block ml-2">
            <p @click="clearStage" class="d-inline-block float-right mt--4 mr-2 mb-0 clear-txt">Clear</p>
            <el-select
              class="select-primary pagination-select"
              v-model=request.api.currentStage
              placeholder="Current Stage"
              @change="filterChanged"
            >
              <el-option
                class="select-primary"
                v-for="(stage , index) in response.stages"
                :key="`${stage.label}_${stage.value}`"
                :label="stage.label"
                :value="`${stage.value}`"
              >
              </el-option>
            </el-select>
          </base-input>

          <base-button type="secondary"
                       class="d-inline-block pt-2 pb-2 ml-auto font-weight-normal custom-button align-self-center filter-button-width"
                       @click="$store.commit('showUCFS')">
            <img class="mr-2" src="/img/svg/filters.svg" alt="Filters"> Filter
            <el-badge :value="getFiltersCount" class="filter-count-badge" type="warning"></el-badge>
          </base-button>

        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 d-flex">
        <div class="table-responsive">
          <el-table :data="response.tblStalledPatientsData"
                    v-loading="loading"
                    row-key="id"
                    class="table-responsive table-flush table-stalled-patient"
                    header-row-class-name="thead-light"
                    @sort-change="sortMethod"
                    lazy
                    :load="getStalledPatientsChildren"
                    :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                    @expand-change="handleExpand"
                    ref="stalledPatients"     
          >
          <el-table-column label="ACTIONS" min-width="130px" header-align="left">
              <template slot-scope="scope" v-if="!scope.row.isChild">
                <el-tooltip
                  class="item" effect="dark"
                  :content="'Send a reminder'"
                  placement="bottom-start"
                  :visible-arrow="false"
                >
                  <img class="pointer mr-2" @click="toggleReminderModal(scope.row)" src="/img/icons/buttons/message.svg"
                       alt=""/>
                </el-tooltip>
                <el-tooltip
                  class="item" effect="dark"
                  :content="'Do you want to snooze this alert? Patient will not show up in Stalled list for XX number of days.'"
                  placement="bottom-start"
                  :visible-arrow="false"
                >
                  <img class="pointer mr-2" @click="toggleSnoozeModal(scope.row)" src="/img/svg/snooze.svg" alt=""/>
                </el-tooltip>
                <el-tooltip
                  class="item" effect="dark"
                  :content="'This will remove patients from the stalled list. They will only show back up in the stall list after they check off an item and then stall again.'"
                  placement="bottom-start"
                  :visible-arrow="false"
                >
                  <img class="pointer mr-2" @click="toggleDeleteModal(scope.row)"
                       src="/img/icons/buttons/cross-bold.svg" alt=""/>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="PATIENT NAME" min-width="110px" max-width="110px">
              <template slot-scope="scope" v-if="!scope.row.isChild">
              <div class="d-flex justify-content-between">
                <el-tooltip
                  class="item" effect="dark"
                  :content="hoverPatientInfo(scope.row)"
                  placement="bottom-start"
                  :visible-arrow="false"
                >
                  <router-link :to="{name: 'checklists', params: {id: scope.row.patient_id}}">
                    <span class="color-maroon">{{ scope.row.first_name + ' ' + scope.row.last_name }}</span>
                  </router-link>
                </el-tooltip>
                  <img class="pointer mr-2" v-if="scope.row.hasChildren && !scope.row.isFetchingChildrenData" @click="loadChildrenRowDataManually(scope)" :src="getArrowIcon(scope)" alt=""/>
                  <img class="pointer mr-2" v-else-if="scope.row.isFetchingChildrenData" src="/img/svg/loading.svg" style="height: 23px;width: 23px;" alt=""/>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="LAST USED" prop="last_used" max-width="100px" min-width="90px">
              <template slot-scope="scope">
                {{
                  formatDate(scope.row.last_used)
                }}
              </template>
            </el-table-column>
            <el-table-column label="CHECKLIST ITEM" prop="item" min-width="150px" sortable="custom">
            </el-table-column>
            <el-table-column label="DAYS LATE" prop="days_late" min-width="90px" sortable="custom">
            </el-table-column>
            <el-table-column label="SURGERY DATE" prop="surgery_date" min-width="115px" sortable="custom">
              <template slot-scope="scope">
                {{
                  showSurgeryDate(scope.row.surgery_date)
                }}
              </template>
            </el-table-column>
            <el-table-column label="DUE DATE" prop="due_date" min-width="170px">
              <template slot-scope="scope">
                <custom-date-picker
                  :inputData.sync="scope.row.due_date"
                  :format="'YYYY/MM/DD'"
                  :icon_width="'39px'"
                  :minDate="new Date()"
                  :show-image-icon="false"
                  @change="updateToDoDueDate(scope.row)">
                </custom-date-picker>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-3">
        <seminar-surgery-footer :pagination="pagination" @changePage="changePage"
                                @changedPageLength="changedPageLength"/>
      </div>
    </div>
  </div>
</template>

<script>
import SeminarSurgeryFooter from "./SeminarSurgeryFooter";
import {Option, Select, Table, TableColumn} from "element-ui";
import Helper from "@/util/globalHelpers";
import CustomDatePicker from "../../Components/CustomDatePicker";

import moment from 'moment';

export default {
  name: "StalledPatients",
  props: ['tab'],
  components: {
    SeminarSurgeryFooter,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    CustomDatePicker
  },
  data() {
    return {
      loading: false,
      modals: {
        reminderModal: false,
        snoozeModal: false,
        deleteModal: false,
      },
      request: {
        api: {
          patientId: '',
          firstName: '',
          lastName: '',
          currentStage: '',
          currentChecklist: '',
          checklistItemId: '',
          todoDueDate: '',
          sortBy: 'days_late',
          sortByDir: 'ASC',
          surgeryDate: '',
          surgeryDateDuration: '',
        },
        notification: '',
        snoozeOptions: {
          r1: {value: '1', label: '1 Day'},
          r2: {value: '7', label: '7 Days'},
          r3: {value: '14', label: '14 Days'},
          r4: {value: '30', label: '30 Days'},
        },
        selectedSnoozePeriod: 'r1',
      },
      response: {
        tblStalledPatientsData: [],
        stages: [],
        checklistItems: [],
        categories: [],
        defaultCategories: [],
      },
      tableColumns: [
        {
          prop: 'patient_name',
          label: 'PATIENT NAME',
          minWidth: 200,
          align: 'center'
        },
        {
          prop: 'last_used',
          label: 'LAST USED',
          minWidth: 200,
        },
        {
          prop: 'checklist_item',
          label: 'CHECKLIST ITEM',
          minWidth: 200,
        },
        {
          prop: 'complete',
          label: 'COMPLETE',
          minWidth: 200,
        },
        {
          prop: 'start_date',
          label: 'START DATE',
          minWidth: 200,
        },
      ],
      pagination: {
        currentPage: 1,
        perPage: 20,
        page: 1,
        lastPage: 1,
      },
      filtersCount: 0,
      selectedPatientTags: [],
    }
  },
  activated() {
    this.getFiltersData();
    let vm = this;
    this.$root.$on('UserSubCategoryFilterCount', (data) => {
      vm.filtersCount = data;
    });
    this.$root.$on('applyUserSubCategoryFilters', (data) => {
      vm.filtersCount = data.filtersCount;
      this.pagination.page = 1;
      vm.selectedPatientTags = data.obj;
      vm.request.api.surgeryDate = data.surgeryDate;
      vm.request.api.surgeryDateDuration = data.surgeryDateDuration;
      vm.getStalledPatients(data.obj);
    });
  },

  deactivated() {
    this.$root.$off('UserSubCategoryFilterCount');
    this.$root.$off('applyUserSubCategoryFilters');
  },
  computed: {
    getFiltersCount() {
      return this.filtersCount ?? 0;
    },
  },
  methods: {

    hoverPatientInfo(row) {
      let returnable = ''
      returnable = (row.hasOwnProperty('first_name') === true) ? returnable + row.first_name + ' ' + row.last_name : returnable + ''
      returnable = (row.hasOwnProperty('email') === true && row.email !== '' && row.email != null) ? returnable + ', ' + row.email : returnable + ''
      returnable = (row.hasOwnProperty('phone') === true && row.phone !== '' && row.phone != null) ? returnable + ', ' + row.phone : returnable + ''
      if (row.hasOwnProperty('dob') === true && row.dob !== '' && row.dob != null) {
        if (!isNaN(Date.parse(row.dob))) {
          const date = new Date(row.dob)
          returnable = returnable + ', ' + Helper.extractAndFormatDate(date.toDateString(), false)
        }
      } else {
        returnable = returnable + ''
      }
      returnable = (row.hasOwnProperty('mrn') === true && row.mrn !== '' && row.mrn != null) ? returnable + ', ' + row.mrn : returnable + ' '
      return returnable
    },

    getFiltersData() {
      let vm = this;
      vm.loading = true;
      axios.get(this.$store.getters.getBaseUrl + '/api/seminar2surgery/filters-data?tab=2'
      ).then((response) => {
        vm.response.stages = response.data.data.stages;
        vm.request.api.currentStage = response.data.data.default_stage ? response.data.data.default_stage : '50';
        vm.response.checklistItems = response.data.data.checklistItems;
        vm.response.categories = response.data.data.categories;
        vm.response.defaultCategories = response.data.data.defaultCategories;
        this.$store.commit('setUserSubcategories', {
          categories: vm.response.categories,
          defaultCategories: vm.response.defaultCategories
        })
        let obj = [];
        vm.response.categories.forEach((category, index) => {
          var i = vm.response.defaultCategories.findIndex(dc => {
            if (dc.user_category_id == category.id) {
              return true;
            }
          });
          if (i != -1) {
            obj.push(vm.response.defaultCategories[i].user_sub_category_id);
          }
        });
        vm.selectedPatientTags = obj;
        vm.getStalledPatients(obj);
      }).catch(error => {
        console.log(error.response)
      });
    },

    filterChanged() {
      this.pagination.page = 1;
      this.createCategoriesArrAndApiCall();
    },

    clearStage() {
      this.request.api.currentStage = '50';
      this.pagination.page = 1;
      this.createCategoriesArrAndApiCall();
    },

    clearChecklistItem() {
      this.request.api.currentChecklist = '';
      this.pagination.page = 1;
      this.createCategoriesArrAndApiCall();
    },

    clearUserCategories() {
      this.createCategoriesArrAndApiCall();
    },
    changePage(page) {
      if (this.pagination.page != page) {
        this.pagination.page = page;
        this.createCategoriesArrAndApiCall();
      }
    },

    changedPageLength(pageLength) {
      this.pagination.page = 1;
      this.pagination.perPage = pageLength;
      this.createCategoriesArrAndApiCall();
    },

    createCategoriesArrAndApiCall() {
      let userSubCategoriesIds = [];
      this.response.categories.forEach(category => {
        if (category.state && !isNaN(category.state)) {
          userSubCategoriesIds.push(category.state);
        }
      });
      this.selectedPatientTags = userSubCategoriesIds;
      this.getStalledPatients(userSubCategoriesIds);
    },

    getStalledPatients(userSubCategories = null) {
      let vm = this;
      vm.loading = true;
      this.$store.commit('setCancellationToken', 'getStalledPatients');
      axios.get(this.$store.getters.getBaseUrl + '/api/seminar2surgery/stalled-patients', {
          params: {
            ...vm.request.api,
            page: vm.pagination.page,
            perPage: vm.pagination.perPage,
            user_sub_categories: userSubCategories && userSubCategories.length > 0 ? userSubCategories : null
          },
          cancelToken: this.$store.state.cancelRequests['getStalledPatients'].token,
        }
      ).then((response) => {
        vm.response.tblStalledPatientsData = [];
        vm.response.tblStalledPatientsData = [...response.data.data.data.map((el) =>{ return {...el,due_date: el.due_date ? moment(el.due_date).format("MM/DD/YYYY"): ''}})];
        vm.pagination.currentPage = response.data.data.current_page;
        vm.pagination.lastPage = response.data.data.last_page;
        vm.pagination.perPage = response.data.data.per_page;
        vm.pagination.page = response.data.data.current_page;
        vm.pagination.total = response.data.data.total;
        vm.loading = false;
      }).catch(error => {
        if(!axios.isCancel(error)){
          console.log(error);
        }
      });
    },

    async getStalledPatientsChildren(row, treeNode, resolve){
      let children_ids = row?.children_ids?.split(',').filter((el) => el != row.id);
      row.isFetchingChildrenData = true;
      let vm = this;
        try {
        let response = await vm.$store.dispatch('_getStalledPatientsChildren', {children_ids});
        let data = response?.data?.data || []; 
        let childrenData = data.map((el) => {
          return {
            ...el, isChild: true,
            due_date: el.due_date ? moment(el.due_date).format("MM/DD/YYYY") : ''
          }
        })
        resolve(childrenData)
        } catch (error) {
          console.log(error);
        }
      row.isFetchingChildrenData = false;

    },
    loadChildrenRowDataManually(scope){
      this.$refs.stalledPatients.store.loadOrToggle(scope.row);
    },
    handleExpand(row,expanded){
      row.isTableRowExpanded = expanded;
    },
    getArrowIcon(scope){
      return scope.row.isTableRowExpanded ? '/img/icons/buttons/arrow-up.svg':'/img/icons/buttons/arrow-down.svg';
    },
    toggleReminderModal(row) {
      this.modals.reminderModal = !this.modals.reminderModal;
      this.request.api.patientId = row.patient_id;
    },

    toggleSnoozeModal(row) {
      console.log(row);
      this.modals.snoozeModal = !this.modals.snoozeModal;
      this.request.api.patientId = row.patient_id;
    },

    toggleDeleteModal(row) {
      this.modals.deleteModal = !this.modals.deleteModal;
      this.request.api.patientId = row.patient_id;
    },

    updateToDoDueDate(row) {
      if (row.due_date != '') {
        let vm = this;
        axios.post(`${this.$store.getters.getBaseUrl}/api/seminar2surgery/update-checklist-due-date`,
          {
            id: row.id,
            checklistId: row.checklist_id,
            date: row.due_date,
          }
        )
          .then((response) => {
            let message = response.data.message;
            vm.$notify.success({
              title: 'Seminar 2 Surgery',
              message: message
            });
          })
          .catch((error) => {
            let message = 'Something went wrong please try again in few minutes.';
            vm.$notify.error({
              title: 'Seminar 2 Surgery',
              message: message
            })
          });
      }
    },

    sendReminderNotification() {
      if (this.request.notification.trim() == '') {
        this.$notify.error({
          title: 'Error',
          message: 'Message field is required'
        });
        return;
      }
      let vm = this;
      axios.post(`${this.$store.getters.getBaseUrl}/api/seminar2surgery/reminder/notification`,
        {
          patientId: vm.request.api.patientId,
          notification: vm.request.notification
        }
      )
        .then((response) => {
          let message = response.data.message;
          vm.$notify.success({
            title: 'Seminar 2 Surgery',
            message: message
          });
          vm.request.api.patientId = '';
          vm.request.notification = '';
          this.modals.reminderModal = !this.modals.reminderModal;
        })
        .catch((error) => {
          let message = 'Something went wrong please try again in few minutes.';
          if (error.response) {
            let errors = error.response.data.errors;
            let firstError = Object.keys(errors)[0];
            message = errors[firstError][0];
          }
          vm.$notify.error({
            title: 'Seminar 2 Surgery',
            message: message
          })
        });
    },

    snoozePatient() {
      let vm = this;
      axios.post(`${this.$store.getters.getBaseUrl}/api/seminar2surgery/snooze-alert`,
        {
          patientId: vm.request.api.patientId,
          snoozePeriod: vm.request.snoozeOptions[vm.request.selectedSnoozePeriod].value
        }
      )
        .then((response) => {
          let message = response.data.message;
          vm.$notify.success({
            title: 'Seminar 2 Surgery',
            message: message
          });
          vm.request.api.patientId = '';
          vm.request.selectedSnoozePeriod = 'r1';
          vm.modals.snoozeModal = !vm.modals.snoozeModal;
          vm.getStalledPatients();
        })
        .catch((error) => {
          let message = 'Something went wrong please try again in few minutes.';
          vm.$notify.error({
            title: 'Seminar 2 Surgery',
            message: message
          })
        });
    },

    dismissStalledPatient() {
      let vm = this;
      axios.post(`${this.$store.getters.getBaseUrl}/api/seminar2surgery/dismiss-stalled-patient`,
        {
          patientId: vm.request.api.patientId,
        }
      )
        .then((response) => {
          let message = response.data.message;
          vm.$notify.success({
            title: 'Seminar 2 Surgery',
            message: message
          });
          vm.request.api.patientId = '';
          vm.modals.deleteModal = !vm.modals.deleteModal;
          vm.getStalledPatients();
        })
        .catch((error) => {
          let message = 'Something went wrong please try again in few minutes.';
          if (error.response) {
            let errors = error.response.data.errors;
            let firstError = Object.keys(errors)[0];
            message = errors[firstError][0];
          }
          vm.$notify.error({
            title: 'Seminar 2 Surgery',
            message: message
          })
        });
    },

    exportStalledPatients() {
      let vm = this;
      const config = {
        responseType: "blob",
        params: {
          ...vm.request.api,
          patient_tags: vm.selectedPatientTags
        }
      };
      axios.get(`${vm.$store.getters.getBaseUrl}/api/seminar2surgery/stalled-patients/excel`,
        config,
      ).then(response => {
        const url = URL.createObjectURL(new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }))
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Baritastic-Stalled-Patients.xlsx`)
        document.body.appendChild(link);
        link.click();
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        vm.$emit('exportComplete')
      });
    },

    showSurgeryDate(surgeryDate) {
      let date = '';
      if (surgeryDate) {
        date = moment(surgeryDate).format('MM/DD/YYYY');
      }
      return date;
    },

    formatDate(oldDate) {
      let date = '';
      if (oldDate) {
        date = moment(oldDate).format('MMM D, YYYY');
      }
      return date;
    },

    sortMethod({prop, order}) {
      if (order) {
        this.request.api.sortBy = prop;
        this.request.api.sortByDir = order == 'ascending' ? 'ASC' : 'DESC';
      } else {
        this.request.api.sortBy = "days_late";
        this.request.api.sortByDir = 'ASC';
      }
      this.pagination.page = 1;
      this.createCategoriesArrAndApiCall();
    }
  },
  watch: {}
}
</script>

<style scoped>
.clear-txt {
  text-align: left;
  font: normal normal normal 9px/13px Open Sans;
  letter-spacing: 0px;
  color: #BB2255;
  cursor: pointer;
}

</style>
<style>
.base-radio-cls .custom-control-input:checked ~ .custom-control-label::before  {
  border-color: #2F597A;
  background-color: #2F597A;
}

.radioButtonCard {
  padding: 8px 11.5px !important;
  box-shadow: 0px 3px 2px #E9ECEF0D;
  border: 1px solid #DEE2E6;
  border-radius: 8px;
  opacity: 1;
  width: 228px;
  height: 53px;
}

.radioButtonCard :focus {
  background-color: #94B527 !important;
}

.radioButtonCard .custom-control-label {
  vertical-align: unset !important;
}


.radioButtonCard .custom-radio .custom-control-input ~ .custom-control-label {
  margin-top: -9px;
}

.stalledPatientReminderModal .modal-body {
  padding-top: 0px;
}

.stalledPatientReminderModal .stalled-patients-reminder-message {
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #4D4D4D;
  opacity: 1;
}

.stalledPatientSnoozeModal .modal-body {
  padding-top: 0px;
}

.stalledPatientSnoozeModal .stalled-patients-snooze-message {
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #4D4D4D;
  opacity: 1;
}

.btn-purple {
  color: white;
  background-color: #8898aa;
  border-radius: 9px;
}

.table-stalled-patient .input_height .el-input__inner {
  width: 150px;
}

.el-table__body-wrapper, .el-tabs__content, .table-responsive {
  overflow: visible;
}
.table-stalled-patient .el-table__body-wrapper tr.el-table__row--level-1{
  background-color: #EDF0F5 !important;
}
.table-stalled-patient .el-table__body-wrapper tr.el-table__row--level-1:not(.el-table__row--level-0 + .el-table__row--level-1) td{
  border-top: 1px solid #FDFDFD;
  border-bottom: 1px solid #FDFDFD;
}
/* hiding default expand button manually */
.table-stalled-patient .el-table__expand-icon{
  display: none !important;
}
</style>
