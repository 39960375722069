<template>
  <div class="modals">
    <modal @close="hideModal" :show.sync="$store.state.modals.exportChecklistReportModal"
           class="all-patient-report-export-modal">
      <!-- Notification Modal Header -->
      <template slot="header">
        <h5 class="modal-title">Report</h5>
      </template>
      <!-- Notification Modal Header Finished -->

      <!-- Notification Modal Body -->
      <template slot="default">

        <p class="checklist-report-export-modal-text">
          Which Report you would like to Export?
        </p>

        <div class="row my-4">
          <div class="col-6">
            <div class="pointer d-flex flex-column p-3 h-100 checklist-report-radio-div"
                 :class="{'radio-border-active':reportType == 'non_filtered'}" @click="reportType = 'non_filtered'">
              <label class="checklist-export-modal-radio-label">
                <input type="radio" v-model="reportType" value="non_filtered" class="custom-checklist-report-radio">
                All Patient Progress
              </label>
              <span class="radio-after-text">
              This report shows all patients with active checklists. It shows checklist items that have been completed and items that are still pending.
            </span>
            </div>
          </div>
          <div class="col-6">
            <div class="pointer d-flex flex-column p-3 h-100 checklist-report-radio-div"
                 :class="{'radio-border-active':reportType == 'filtered'}" @click="reportType = 'filtered'">
              <label class="checklist-export-modal-radio-label">
                <input type="radio" v-model="reportType" value="filtered" class="custom-checklist-report-radio">
                Your Filtered Report
              </label>
              <span class="radio-after-text">
              This report will only show the data using the filters that you selected.
            </span>
            </div>
          </div>
        </div>
        <p class="mb-4 checklist-report-export-modal-text">
          This report can take a few minutes to load. Thank you for your patience.
        </p>

      </template>
      <!-- Notification Modal Body Finished -->

      <!-- Notification Modal Footer -->
      <template slot="footer">
        <div class="mt--5">
          <base-button class="btn-purple" type="secondary" @click="hideModal">Cancel</base-button>
          <base-button class="btn-primary-action" type="success" @click="exportReport">Export</base-button>
        </div>
      </template>
      <!-- Notification Modal Footer Finished -->
    </modal>
  </div>
</template>

<script>
export default {
  name: "ExportChecklistReportModal",
  data() {
    return {
      reportType: 'non_filtered',
    }
  },
  methods: {
    exportReport() {
      this.$emit('exportReport', this.reportType)
    },

    hideModal() {
      this.$store.commit('toggleExportChecklistReportModal', false)
    }
  }
}
</script>

<style scoped>

.all-patient-report-export-modal >>> .modal-content, .all-patient-report-export-modal >>> .modal-header, .all-patient-report-export-modal >>> .modal-body {
  width: 700px;
}

.all-patient-report-export-modal >>> .modal-dialog {
  justify-content: center;
}

.custom-checklist-report-radio {
  accent-color: #2F597A;
  margin-right: 7px !important;
}

.checklist-report-radio-div {
  border: 2px solid #DEE2E6;
  border-radius: 4px;
}

.checklist-report-radio-div > label {
  font-weight: 600 !important;
}

.radio-after-text {
  font: normal normal normal 14px/21px Open Sans;
  color: #172B4D;
}

.radio-border-active {
  border: 2px solid #2F597A;
  border-radius: 4px;
}

.checklist-export-modal-radio-label {
  color: #2F597A;
}

.checklist-report-export-modal-text {
  font: normal normal normal 14px/19px Open Sans;
  color: #4D4D4D;
}

.all-patient-report-export-modal >>> .modal-header {
  padding-bottom: 0 !important;
}

</style>
