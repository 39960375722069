<template>
<div>
  <div class="row">
      <div class="col-12 d-flex mb-3">
        <div class="d-flex w-100">
          <base-input class="d-inline-block" v-model="request.api.firstName"
                      label="First Name"
                      placeholder="Search..." @change="filterChanged" type="search" @search="filterChanged">
          </base-input>
          <base-input class="d-inline-block ml-2" v-model="request.api.lastName"
                      label="Last Name"
                      placeholder="Search..." @change="filterChanged" type="search" @search="filterChanged">
          </base-input>

          <base-input label="Current Stage" class="d-inline-block ml-2 mr-2">
            <p @click="clearStage" class="d-inline-block float-right mt--4 mr-2 mb-0 clear-txt">Clear</p>
            <el-select
              class="select-primary pagination-select"
              v-model=request.api.currentStage
              placeholder="Current Stage"
              @change="filterChanged"
            >
              <el-option
                class="select-primary"
                v-for="(stage , index) in response.stages"
                :key="`${stage.label}_${stage.value}`"
                :label="stage.label"
                :value="`${stage.value}`"
              >
              </el-option>
            </el-select>
          </base-input>

          <base-button type="secondary"
                       class="d-inline-block pt-2 pb-2 ml-auto align-self-center font-weight-normal custom-button filter-button-width"
                       @click="$store.commit('showUCFS')">
              <img class="mr-2" src="/img/svg/filters.svg" alt="Filters"> Filter <el-badge :value="getFiltersCount" class="filter-count-badge" type="warning"></el-badge>
          </base-button>
        </div>
      </div>
  </div>
  <div class="row">
    <div class="col-12 d-flex">
      <div class="table-responsive">
        <el-table :data="response.tblCompleteData"
                  v-loading="loading"
                  row-key="id"
                  class="table-responsive table-flush"
                  header-row-class-name="thead-light"
                  @sort-change="sortMethod"
        >
          <el-table-column label="PATIENT NAME" prop="name"  min-width="140px" >
            <template slot-scope="scope">
                <el-tooltip
                  class="item" effect="dark"
                  :content="hoverPatientInfo(scope.row)"
                  placement="bottom-start"
                  :visible-arrow="false"
                >
                  <router-link :to="{name: 'checklists', params: {id: scope.row.patient_id}}">
                    <span class="color-maroon">{{ scope.row.first_name + ' ' + scope.row.last_name }}</span>
                  </router-link>
                </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="LAST USED" prop="last_used" min-width="140px" sortable="custom">
            <template slot-scope="scope">
              {{
                formatDate(scope.row.last_used)
              }}
            </template>
          </el-table-column>
          <el-table-column label="CHECKLIST ITEM" prop="item" min-width="140px" sortable="custom">
          </el-table-column>
          <el-table-column label="COMPLETE" prop="percentage" min-width="140px" sortable="custom">
            <template slot-scope="scope">
              {{ scope.row.percentage }} %
            </template>
          </el-table-column>
          <el-table-column label="START DATE" prop="consult_date" min-width="140px" sortable="custom">
            <template slot-scope="scope">
              {{
                formatDate(scope.row.consult_date)
              }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 mt-3">
      <seminar-surgery-footer :pagination="pagination" @changePage="changePage" @changedPageLength="changedPageLength"/>
    </div>
  </div>
</div>
</template>

<script>
import moment from "moment-timezone";
import SeminarSurgeryFooter from "./SeminarSurgeryFooter";
import {Option, Select, Table, TableColumn} from "element-ui";
import Helper from "@/util/globalHelpers";

export default {
  name: "Complete",
  props :  ['tab'],
  components: {
    SeminarSurgeryFooter,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data(){
    return {
      loading: false,
      request : {
        api: {
          firstName:'',
          lastName:'',
          currentStage:'',
          sortBy:'percentage',
          sortByDir:'DESC',
          surgeryDate: '',
          surgeryDateDuration: '',
        },
      },
      response : {
        tblCompleteData : [],
        stages: [],
        categories: [],
        defaultCategories: [],
      },
      tableColumns: [
        {
          prop: 'patient_name',
          label: 'PATIENT NAME',
          minWidth: 200,
        },
        {
          prop: 'last_used',
          label: 'LAST USED',
          minWidth: 200
        },
        {
          prop: 'checklist_item',
          label: 'CHECKLIST ITEM',
          minWidth: 200,
        },
        {
          prop: 'complete',
          label: 'COMPLETE',
          minWidth: 200,
        },
        {
          prop: 'start_date',
          label: 'START DATE',
          minWidth: 200,
        },
      ],
      pagination : {
        currentPage : 1,
        perPage : 20,
        page:1,
        lastPage:1,
      },
      filtersCount : 0,
      selectedPatientTags: [],
    }
  },
  activated() {
    this.getFiltersData();
    let vm = this;
    this.$root.$on('UserSubCategoryFilterCount',(data)=>{
      vm.filtersCount = data;
    });
    this.$root.$on('applyUserSubCategoryFilters',(data)=>{
      this.pagination.page = 1;
      vm.filtersCount = data.filtersCount;
      vm.selectedPatientTags = data.obj;
      vm.request.api.surgeryDate = data.surgeryDate;
      vm.request.api.surgeryDateDuration = data.surgeryDateDuration;
      vm.getUsersChecklistCompletion(data.obj);
    });
  },
  deactivated() {
    this.$root.$off('UserSubCategoryFilterCount');
    this.$root.$off('applyUserSubCategoryFilters');
  },

  computed : {
    getFiltersCount () {
      return this.filtersCount ?? 0;
    }
  },
  methods : {

    hoverPatientInfo(row){
      let returnable = ''
      returnable = (row.hasOwnProperty('first_name') === true) ? returnable+row.first_name + ' ' + row.last_name : returnable+''
      returnable = (row.hasOwnProperty('email') === true && row.email !== '' && row.email != null) ? returnable+', '+row.email : returnable+''
      returnable = (row.hasOwnProperty('phone') === true && row.phone !== '' && row.phone != null) ? returnable+', '+row.phone : returnable+''
      if (row.hasOwnProperty('dob') === true && row.dob !== '' && row.dob != null){
        if (!isNaN(Date.parse(row.dob))){
          const date = new Date(row.dob)
          returnable = returnable+', '+Helper.extractAndFormatDate(date.toDateString(),false)
        }
      }else {
        returnable = returnable+''
      }
      returnable = (row.hasOwnProperty('mrn') === true && row.mrn !== '' && row.mrn != null) ? returnable + ', ' + row.mrn : returnable + ' '
      return returnable
    },

    getFiltersData(){
      let vm = this;
      vm.loading = true;
      axios.get(this.$store.getters.getBaseUrl + '/api/seminar2surgery/filters-data?tab=1'
      ).then((response) => {
        console.log(response.data.data);
        vm.request.api.currentStage = response.data.data.default_stage ? response.data.data.default_stage : '50';
        vm.response.stages = response.data.data.stages;
        vm.response.categories = response.data.data.categories;
        vm.response.defaultCategories = response.data.data.defaultCategories;
        this.$store.commit('setUserSubcategories',{categories:vm.response.categories,defaultCategories:vm.response.defaultCategories })
        let obj = [];
        vm.response.categories.forEach((category,index)=>{
          var i = vm.response.defaultCategories.findIndex(dc=>{
            if(dc.user_category_id == category.id){
              return true;
            }
          });
          if(i!=-1){
            obj.push(vm.response.defaultCategories[i].user_sub_category_id);
          }
        });
        vm.selectedPatientTags = obj;
        vm.getUsersChecklistCompletion(obj);
      }).catch(error => {
        console.log(error.response)
      });
    },

    filterChanged(){
      this.pagination.page = 1;
      this.createCategoriesArrAndApiCall();
    },

    clearStage(){
      this.request.api.currentStage = '50';
      this.pagination.page = 1;
      this.createCategoriesArrAndApiCall();
    },

    clearUserCategories(){
      this.createCategoriesArrAndApiCall();
    },

    changePage(page){
      if(this.pagination.page != page){
        this.pagination.page = page;
        this.createCategoriesArrAndApiCall();
      }
    },

    changedPageLength(pageLength) {
      this.pagination.page = 1;
      this.pagination.perPage = pageLength;
      this.createCategoriesArrAndApiCall();
    },

    createCategoriesArrAndApiCall(){
      let userSubCategoriesIds = [];
      this.response.categories.forEach(category => {
        if(category.state && !isNaN(category.state)){
          userSubCategoriesIds.push(category.state);
        }
      });
      this.selectedPatientTags = userSubCategoriesIds;
      this.getUsersChecklistCompletion(userSubCategoriesIds);
    },

    getUsersChecklistCompletion(userSubCategories = null){
      let vm = this;
      vm.loading = true;
      this.$store.commit('setCancellationToken', 'getUsersChecklistCompletion');
      axios.get(this.$store.getters.getBaseUrl + '/api/seminar2surgery/completion', {
          params : {
              ...vm.request.api,
              user_sub_categories: userSubCategories && userSubCategories.length > 0 ? userSubCategories : null,
              page: vm.pagination.page,
              perPage: vm.pagination.perPage
            },
          cancelToken: this.$store.state.cancelRequests['getUsersChecklistCompletion'].token,
        }
      ).then((response) => {
        vm.response.tblCompleteData = [];
        vm.response.tblCompleteData = [...response.data.data.data];
        vm.pagination.currentPage = response.data.data.current_page;
        vm.pagination.lastPage = response.data.data.last_page;
        vm.pagination.perPage = response.data.data.per_page;
        vm.pagination.page = response.data.data.current_page;
        vm.pagination.total = response.data.data.total;
        vm.loading = false;
      }).catch(error => {
        if(!axios.isCancel(error)){
          console.log(error);
        }
      });
    },

    exportPercentageCompletion(){
      let vm = this;
      const config = {
        responseType: "blob",
        params : {
              ...vm.request.api,
              patient_tags: vm.selectedPatientTags
            }
      };
      axios.get(`${vm.$store.getters.getBaseUrl}/api/seminar2surgery/completion/excel`,
        config,
      ).then(response => {
        const url = URL.createObjectURL(new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }))
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Baritastic-checklist-percentage-completion-report.xlsx`)
        document.body.appendChild(link);
        link.click();
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        vm.$emit('exportComplete')
      });
    },

    formatDate(oldDate) {
      let date = '';
      if(oldDate){
        date = moment(oldDate).format('MMM D, YYYY');
      }
      return date;
    },

    sortMethod({prop,order})  {
      if(order){
        this.request.api.sortBy = prop;
        this.request.api.sortByDir = order == 'ascending' ? 'ASC' : 'DESC';
      }
      else {
        this.request.api.sortBy = "percentage";
        this.request.api.sortByDir = 'DESC';
      }
      this.pagination.page = 1;
      this.createCategoriesArrAndApiCall();
    }
  },
  watch : {

  }
}
</script>

<style scoped>
.clear-txt {
  text-align: left;
  font: normal normal normal 9px/13px Open Sans;
  letter-spacing: 0px;
  color: #BB2255;
  cursor: pointer;
}

.btnFilters {
  horiz-align: right;
}

.filter-count-badge {
  padding: 0;
  /*margin-top: 8px;*/
}
</style>
